export const GLOBAL_TOASTER_KEY = 'app-level-toast';
export const DEFAULT_DEBOUNCE_TIME_FOR_SEARCH_FIELD_IN_MS = 300;
export const SEARCH_HIGHLIGHT_CSS_CLASS = 'ri-search-highlight';

// standard to application date format which we are using
// across ag-grid tables.
// Example: "9/30/24 2:31 PM"
export const AG_GRID_DATE_FORMAT = 'M/d/yy h:mm a';

// Breakpoints should match to scss breakpoints from here:
// src\styles\scss-variables\breakpoints.scss
export enum BREAKPOINTS {
  medium = 700,
  large = 1440,
}

const imageMimeTypes = [
  'image/apng',
  'image/avif',
  'image/gif',
  'image/jpeg',
  'image/svg+xml',
  'image/webp',
  'image/png',
];
export const pdfMimeType = 'application/pdf';
export const mimeTypesWhichCanBeNativelyOpenedInBrowser = [
  ...imageMimeTypes,
  pdfMimeType,
];

// Some components need additional steps to reset UI state
// during specific event. For example if we choose task
// on the sidebar (and previously we had another task selected),
// not all components are destroyed and recreated from scratch.
// Most of them don't call ngOnInit or constructor block again.
// So by implementing this interface we tell that there is a
// method which you can call on any event you need which will
// reset state of the component (or service, whatever).
// Implementation details are fully on developer.
export interface Resetable {
  resetState: () => void;
}

// AUTH0 throws an exception if you try to request more
// than 50 users per API request.
export const MAX_ALLOWED_PAGE_SIZE_BY_AUTH0_API = 50;
